@font-face {
	font-family : 'Merriweather';
	src: url('/fonts/Merriweather/Merriweather-Regular.ttf');
	font-weight:normal;
	font-style:normal;
	font-display:swap;
}
@font-face {
	font-family : 'Merriweather';
	src: url('/fonts/Merriweather/Merriweather-Bold.ttf');
	font-weight:bold;
	font-style:normal;
	font-display:swap;
}
@font-face {
	font-family : 'Merriweather';
	src: url('/fonts/Merriweather/Merriweather-Light.ttf');
	font-weight:300;
	font-style:normal;
	font-display:swap;
}
/* TODO: Used in one place */
@font-face {
	font-family : 'Merriweather';
	src: url('/fonts/Merriweather/Merriweather-Italic.ttf');
	font-weight:normal;
	font-style:italic;
	font-display:swap;
}

@font-face {
	font-family : 'OpenSans';
	src: url('/fonts/Open_Sans/OpenSans-Regular.ttf');
	font-weight:normal;
	font-style:normal;
	font-display:swap;
}
@font-face {
	font-family : 'OpenSans';
	src: url('/fonts/Open_Sans/OpenSans-Bold.ttf');
	font-weight:bold;
	font-style:normal;
	font-display:swap;
}
